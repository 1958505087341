import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Home from "./Home";

class PaymentEdit extends Component {

    emptyPayment = {
        id: 0,
        amount: 0,
        invoiceId:  this.props.match.params.invoiceId,
        paymentId:  this.props.match.params.paymentId
    };
    emptyInvoice = {
        id: ""
    }
    error = '';
    stop = false;

    constructor(props) {
        super(props);
        this.state = {
            invoice: this.emptyInvoice,
            payment: this.emptyPayment,
            error: this.error
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        try {
            if (this.props.match.params.command === 'add') {
                fetch(`/api/invoices/${this.props.match.params.invoiceId}`)
                    .then(response => response.json())
                    .then(data => {
                        this.setState({invoice: data});
                        this.setState({isLoading: false});
                    });
            } else {
                if (this.props.match.params.invoiceId === '0') {
                    fetch(`/api/payments/${this.props.match.params.paymentId}`)
                        .then(response => response.json())
                        .then(data => {
                            this.setState({payment: data});
                            fetch(`/api/invoices/${this.state.payment.invoiceId}`)
                                .then(response => response.json())
                                .then(data => {
                                    this.setState({invoice: data});
                                    this.setState({isLoading: false});
                                })
                        });
                } else {
                    Promise.all([
                        fetch(`/api/payments/${this.props.match.params.paymentId}`).then(res => res.json()),
                        fetch(`/api/invoices/${this.props.match.params.invoiceId}`).then(res => res.json())
                    ]).then(([paymentData, invoiceData]) => {
                        this.setState({payment: paymentData, invoice: invoiceData});
                        this.setState({isLoading: false});
                    });
                }
            }
        } catch (error) {
            this.error = "Invoice or payment not found.";
            this.stop = true;
            this.forceUpdate();
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let payment = {...this.state.payment};
        payment[name] = value;
        this.setState({payment});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {payment} = this.state;

        await fetch('/api/payments', {
            method: (payment.id) ? 'PATCH' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payment),
        }).then((response) => {
            if (response.ok) {
                this.props.history.push(`/payments/show/${this.props.match.params.customerId}/${this.props.match.params.invoiceId}/0`);
            } else {
                if (response.headers.get('reactError') === '') {
                    this.error = 'Error code: ' + response.status;
                } else {
                    this.error = response.headers.get('reactError') + ' (Status: ' + response.status + ')';
                }
                this.forceUpdate();
            }
        }).catch((error) => {
            this.error = 'Network error';
            this.forceUpdate();
        });
    }

    render() {
        const title = <h2>{this.state.payment.id ?
            'Edit Payment #' + this.state.payment.id + ' for Invoice #' + this.state.invoice.id + ':'
            :
            'New Payment for Invoice #' + this.state.invoice.id + ":"
        }</h2>;

        let errorBox = '';
        if (this.error !== '') {
            errorBox = <div><p class="alert alert-danger"><strong>Error: </strong>{this.error}</p></div>;
        }

        let submitButton = '';
        if (this.props.match.params.command === 'add') {
            submitButton = <Button color="success" type="submit">Submit</Button>
        } else {
            submitButton = <Button color="warning" type="submit">Update</Button>
        }

        const bottomButtonsLink = "/payments/show/" + this.props.match.params.customerId + "/" + this.props.match.params.invoiceId + "/0";

        if (this.stop) {
            return (
                <div>
                    <Home/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {errorBox}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
        <div>
            <Home/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {errorBox}
                        {title}
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="amount">Amount</Label>
                                <Input type="number" name="amount" id="amount" value={this.state.payment.amount || ''}
                                       onChange={this.handleChange} autoComplete="amount"/>
                            </FormGroup>
                            <FormGroup>
                                {submitButton} &nbsp;
                                <Button color="primary" tag={Link} to={bottomButtonsLink}>Cancel</Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        );
    }

}

export default withRouter(PaymentEdit);