import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CustomerList from './CustomerList';
import CustomerEdit from "./CustomerEdit";
import InvoiceList from "./InvoiceList";
import InvoiceEdit from "./InvoiceEdit"
import PaymentList from "./PaymentList";
import PaymentEdit from "./PaymentEdit";
import MagicLink from "./MagicLink";

class App extends Component {
  render() {
    return (
        <Router forceRefresh={true}>
            <Switch>
                <Route path='/customers' component={CustomerList}/>
                <Route path='/magiclinknotused/:magiclink' component={MagicLink}/>
                <Route path='/customer/:id' component={CustomerEdit}/>
                <Route path='/allInvoices/:command/:customerId/:invoiceId' component={InvoiceList}/>
                <Route path='/invoices/:command/:customerId/:invoiceId' component={InvoiceList}/>
                <Route path='/invoiceForm/:command/:customerId/:invoiceId' component={InvoiceEdit}/>
                <Route path='/allPayments/:command/:customerId/:invoiceId/:paymentId' component={PaymentList}/>
                <Route path='/payments/:command/:customerId/:invoiceId/:paymentId' component={PaymentList}/>
                <Route path='/paymentForm/:command/:customerId/:invoiceId/:paymentId' component={PaymentEdit}/>
                <Route exact path='/' component={Home}/>
                <Route component={PaymentList}/>
            </Switch>
        </Router>
    )
  }
}

export default App;