import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Home from "./Home";

class CustomerEdit extends Component {

    emptyItem = {
        name: '',
        ico: ''
    };
    error = '';
    stop = false;

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            error: this.error
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            try {
                const customer = await (await fetch(`/api/customers/${this.props.match.params.id}`)).json();
                this.setState({item: customer});
            } catch (error) {
                this.error = "Customer not found.";
                this.stop = true;
                this.forceUpdate();
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;

        await fetch('/api/customers', {
            method: (item.id) ? 'PATCH' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item),
        }).then((response) => {
            if (response.ok) {
                this.props.history.push('/customers');
            } else {
                if (response.headers.get('reactError') === '') {
                    this.error = 'Error code: ' + response.status;
                } else {
                    this.error = response.headers.get('reactError') + ' (Status: ' + response.status + ')';
                }
                this.setState({item});
            }
        }).catch((error) => {
            this.error = 'Network error';
            this.forceUpdate();
        });
    }

    render() {
        const {item} = this.state;
        const title = <h2>{item.id ? 'Edit Customer #' + item.id + ":" : 'Add Customer'}</h2>;
        let errorBox = '';
        if (this.error !== '') {
            errorBox = <div><p class="alert alert-danger"><strong>Error: </strong>{this.error}</p></div>;
        }

        let submitButton = '';
        if (this.props.match.params.id === 'new') {
            submitButton = <Button color="success" type="submit">Submit</Button>
        } else {
            submitButton = <Button color="warning" type="submit">Update</Button>
        }

        if (this.stop) {
            return (
                <div>
                    <Home/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {errorBox}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
        <div>
            <Home/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {errorBox}
                        {title}
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" value={item.name || ''}
                                       onChange={this.handleChange} autoComplete="name"/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">IČO</Label>
                                <Input type="text" name="ico" id="ico" value={item.ico || ''}
                                       onChange={this.handleChange} autoComplete="ico"/>
                            </FormGroup>
                            <FormGroup>
                                {submitButton} &nbsp;
                                <Button color="primary" tag={Link} to="/customers">Cancel</Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        );
    }

}

export default withRouter(CustomerEdit);