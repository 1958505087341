import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import Home from './Home';
import { Link } from 'react-router-dom';

class InvoiceList extends Component {

    emptyItem = {
        name: '',
        ico: ''
    };
    error = '';
    stop = false;

    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            item: this.emptyItem,
            error: this.error,
            isLoading: true
        };
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        try {
            if (this.props.match.params.customerId === '0') {
                fetch('/api/invoices')
                    .then(response => response.json())
                    .then(data => {
                        this.setState({invoices: data});
                        this.setState({isLoading: false});
                    });
            } else {
                Promise.all([
                    fetch(`/api/customers/${this.props.match.params.customerId}`).then(res => res.json()),
                    fetch(`/api/invoices/customer/${this.props.match.params.customerId}`).then(res => res.json())
                ]).then(([customerData, invoiceData]) => {
                    this.setState({item: customerData, invoices: invoiceData});
                    this.setState({isLoading: false});
                });
            }
        } catch (error) {
            this.error = "Invoices not found.";
            this.stop = true;
            this.forceUpdate();
        }
    }

    async pay(id) {
        this.error = '';
        await fetch(`/api/invoices/${id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                window.location.reload();
            } else {
                if (response.headers.get('reactError') === '') {
                    this.error = 'Error code: ' + response.status;
                } else {
                    this.error = response.headers.get('reactError') + ' (Status: ' + response.status + ')';
                }
            }
        }).catch((error) => {
            this.error = 'Network error';
            this.setState(this.state)
        });
    }

    async remove(id) {
        this.error = '';
        await fetch(`/api/invoices/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                let updatedInvoices = [...this.state.invoices].filter(i => i.id !== id);
                this.setState({invoices: updatedInvoices});
            } else {
                if (response.headers.get('reactError') === '') {
                    this.error = 'Error code: ' + response.status;
                    this.forceUpdate();
                } else {
                    this.error = response.headers.get('reactError') + ' (Status: ' + response.status + ')';
                    this.forceUpdate();
                }
            }
        }).catch((error) => {
            this.error = 'Network error';
            this.forceUpdate();
        });
    }

    render() {

        const invoiceList = this.state.invoices.map(invoice => {
            return <tr key={invoice.id} className={ invoice.paid === invoice.due ? 'table-success' :  invoice.paid < invoice.due ? 'table-warning' : 'table-danger' }>
                <td>{invoice.id}</td>
                <td>{invoice.due}</td>
                <td>{invoice.paid}</td>
                <td>{invoice.customerId}</td>
                <td>{invoice.paymentList.length}</td>
                <td>{ invoice.paid === invoice.due ? 'Paid' :  invoice.paid < invoice.due ? 'Not Paid' : 'Overpaid' }</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" tag={Link} to={"/payments/show/" + this.props.match.params.customerId + '/' + invoice.id + '/0'}>Payments</Button>&nbsp;&nbsp;
                        <Button size="sm" color="warning" tag={Link} to={"/invoiceForm/edit/" + this.props.match.params.customerId + '/' + invoice.id}>Edit</Button>&nbsp;&nbsp;
                        <Button size="sm" color="danger" onClick={() => this.remove(invoice.id)}>Delete</Button>&nbsp;&nbsp;
                        <Button size="sm" color="success" onClick={() => this.pay(invoice.id)}>Pay</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        const bottomButtonsLink = "/invoiceForm/add/" + this.props.match.params.customerId + "/0";

        let header = "";
        if (this.props.match.params.customerId === '0' || this.state.isLoading) {
            header = "List of Invoices:";
        } else {
            header = "List of Invoices of " + this.state.item.name + ", IČO " + this.state.item.ico + ":";
        }

        let errorBox = '';
        if (this.error !== '') {
            errorBox = <div><p class="alert alert-danger"><strong>Error: </strong>{this.error}</p></div>;
        }

        if (this.stop) {
            return (
                <div>
                    <Home/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {errorBox}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <Home/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {errorBox}
                            <h2>
                            {header}
                            </h2>
                            <table className="table table-hover table-condensed">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Total Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Customer Id</th>
                                    <th>Payments</th>
                                    <th>Balance</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {invoiceList}
                                </tbody>
                            </table>
                            {this.props.match.params.customerId !== '0' ? <div>
                                <Button color="success" tag={Link} to={bottomButtonsLink}>Add Invoice</Button>&nbsp;&nbsp;
                                <Button color="primary" tag={Link} to="/customers">Back</Button>&nbsp;&nbsp;
                                </div> : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InvoiceList;