import React, { Component } from 'react';

class MagicLink extends Component {

    sessionKey = ''

    componentDidMount() {
        try {
            console.log('processing magic link ' + this.props.match.params.magiclink);
            fetch(`/magiclink/${this.props.match.params.magiclink}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    console.log(res.status);
                    console.log(res);
                    console.log(res.headers);
                    console.log(res.toString());
                    console.log(res.headers.get('set-cookie'))
                })
                .catch(error => console.log(error));
        } catch (error) {
            console.log('error');
            this.props.history.push('http://localhost:8084');
        }
    }

    render() {
        console.log('render');
        return (
            <div>Welcome</div>
        );

    }
}

export default MagicLink;