import React, { Component } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

class Home extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>&nbsp;</p>
                        <h1>React & Java Spring Boot Demo</h1>
                        <p><strong>Application description is available at <a href="https://jan.petrzilka.cz/react">https://jan.petrzilka.cz/react</a>.</strong></p>
                        <p>&nbsp;</p>
                        <p>
                            <Button color="primary" tag={Link} to="/customers">List of Customers</Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color="primary" tag={Link} to="/allInvoices/show/0/0">List of Invoices</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color="primary" tag={Link} to="/allPayments/show/0/0/0">List of Payments</Button>
                        </p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;