import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import Home from './Home';
import { Link } from 'react-router-dom';

class CustomerList extends Component {

    error = '';
    stop = false;

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            error: this.error
        };
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        try {
            fetch('/api/customers')
                .then(response => response.json())
                .then(data => this.setState({customers: data}));
        } catch (error) {
            this.error = "Customers not found.";
            this.stop = true;
            this.forceUpdate();
        }
    }

    async remove(id) {
        this.error = '';
        await fetch(`/api/customers/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                let updatedCustomers = [...this.state.customers].filter(i => i.id !== id);
                this.setState({customers: updatedCustomers});
            } else {
                if (response.headers.get('reactError') === '') {
                    this.error = 'Error code: ' + response.status;
                    this.forceUpdate();
                } else {
                    this.error = response.headers.get('reactError') + ' (Status: ' + response.status + ')';
                    this.forceUpdate();
                }
            }
        }).catch((error) => {
            this.error = 'Network error';
            this.forceUpdate();
        });
    }

    render() {

        const customerList = this.state.customers.map(customer => {
            return <tr key={customer.id}>
                <td>{customer.id}</td>
                <td style={{whiteSpace: 'nowrap'}}>{customer.name}</td>
                <td>{customer.ico}</td>
                <td>{customer.invoiceList.length}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" tag={Link} to={"/invoices/show/" + customer.id + '/0'}>Invoices</Button>&nbsp;&nbsp;
                        <Button size="sm" color="warning" tag={Link} to={"/customer/" + customer.id}>Edit</Button>&nbsp;&nbsp;
                        <Button size="sm" color="danger" onClick={() => this.remove(customer.id)}>Delete</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        let errorBox = '';
        if (this.error !== '') {
            errorBox = <div><p class="alert alert-danger"><strong>Error: </strong>{this.error}</p></div>;
        }

        if (this.stop) {
            return (
                <div>
                    <Home/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {errorBox}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <Home/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {errorBox}
                            <h2>
                            List of Customers:
                            </h2>
                            <table className="table table-hover table-condensed">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>IČO</th>
                                    <th>Invoices</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {customerList}
                                </tbody>
                            </table>
                            <div>
                                <Button color="success" tag={Link} to="/customer/new">Add Customer</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerList;