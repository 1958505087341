import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import Home from './Home';
import { Link } from 'react-router-dom';

class PaymentList extends Component {

    emptyInvoice = {
        id: 0
    };
    error = '';
    stop = false;

    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            invoice: this.emptyInvoice,
            error: this.error,
            isLoading: true
        };
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        try {
            if (this.props.match.params.invoiceId === '0') {
                fetch('/api/payments')
                    .then(response => response.json())
                    .then(data => {
                        this.setState({payments: data});
                        this.setState({isLoading: false});
                    });
            } else {
                Promise.all([
                    fetch(`/api/invoices/${this.props.match.params.invoiceId}`).then(res => res.json()),
                    fetch(`/api/payments/invoice/${this.props.match.params.invoiceId}`).then(res => res.json())
                ]).then(([invoiceData, paymentData]) => {
                    this.setState({invoice: invoiceData, payments: paymentData});
                    this.setState({isLoading: false});
                });
            }
        } catch (error) {
            this.error = "Customer or payments not found.";
            this.stop = true;
            this.forceUpdate();
        }
    }

    async remove(id) {
        this.error = '';
        await fetch(`/api/payments/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                let updatedPayments = [...this.state.payments].filter(i => i.id !== id);
                this.setState({payments: updatedPayments});
            } else {
                if (response.headers.get('reactError') === '') {
                    this.error = 'Error code: ' + response.status;
                    this.forceUpdate();
                } else {
                    this.error = response.headers.get('reactError') + ' (Status: ' + response.status + ')';
                    this.forceUpdate();
                }
            }
        }).catch((error) => {
            this.error = 'Network error';
            this.forceUpdate();
        });
    }

    render() {

        const paymentList = this.state.payments.map(payment => {
            return <tr key={payment.id} >
                <td>{payment.id}</td>
                <td>{payment.amount}</td>
                <td>{payment.invoiceId}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="warning" tag={Link} to={"/paymentForm/edit/" + this.props.match.params.customerId + '/' + this.props.match.params.invoiceId + '/' + payment.id}>Edit</Button>&nbsp;&nbsp;
                        <Button size="sm" color="danger" onClick={() => this.remove(payment.id)}>Delete</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        const bottomButtonsLink = "/paymentForm/add/" + this.props.match.params.customerId + "/" + this.props.match.params.invoiceId + "/0";

        const backLink = "/invoices/show/" + this.props.match.params.customerId + "/" + this.props.match.params.invoiceId;

        let header = "";
        if (this.props.match.params.invoiceId === '0' || this.state.isLoading) {
            header = "List of Payments:";
        } else {
            header = "List of Payments for Invoice #" + this.props.match.params.invoiceId + ":";
        }

        let errorBox = '';
        if (this.error !== '') {
            errorBox = <div><p class="alert alert-danger"><strong>Error: </strong>{this.error}</p></div>;
        }

        if (this.stop) {
            return (
                <div>
                    <Home/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {errorBox}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <Home/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"><h2>
                            {header}</h2>
                            <table className="table table-hover table-condensed">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Amount</th>
                                    <th>Invoice Id</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {paymentList}
                                </tbody>
                            </table>
                            {this.props.match.params.invoiceId !== '0' ? <div>
                                <Button color="success" tag={Link} to={bottomButtonsLink}>Add Payment</Button>&nbsp;&nbsp;
                                <Button color="primary" tag={Link} to={backLink}>Back</Button>&nbsp;&nbsp;
                                </div> : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentList;