import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Home from "./Home";

class InvoiceEdit extends Component {

    emptyInvoice = {
        id: 0,
        due: 0,
        paid: 0,
        customerId: this.props.match.params.customerId
    };
    emptyCustomer = {
        name: "",
        ico: ""
    }
    error = '';
    stop = false;

    constructor(props) {
        super(props);
        this.state = {
            invoice: this.emptyInvoice,
            customer: this.emptyCustomer,
            error: this.error
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        try {
            if (this.props.match.params.command === 'add') {
                fetch(`/api/customers/${this.props.match.params.customerId}`)
                    .then(response => response.json())
                    .then(data => {
                        this.setState({customer: data});
                        this.setState({isLoading: false});
                    });
            } else {
                if (this.props.match.params.customerId === '0') {
                    fetch(`/api/invoices/${this.props.match.params.invoiceId}`)
                        .then(response => response.json())
                        .then(data => {
                            this.setState({invoice: data});
                            fetch(`/api/customers/${this.state.invoice.customerId}`)
                                .then(response => response.json())
                                .then(data => {
                                    this.setState({customer: data});
                                    this.setState({isLoading: false});
                                })
                        });
                } else {
                    Promise.all([
                        fetch(`/api/customers/${this.props.match.params.customerId}`).then(res => res.json()),
                        fetch(`/api/invoices/${this.props.match.params.invoiceId}`).then(res => res.json())
                    ]).then(([customerData, invoiceData]) => {
                        this.setState({customer: customerData, invoice: invoiceData});
                        this.setState({isLoading: false});
                    });
                }
            }
        } catch (error) {
            this.error = "Customer or invoice not found.";
            this.stop = true;
            this.forceUpdate();
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let invoice = {...this.state.invoice};
        invoice[name] = value;
        this.setState({invoice});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {invoice} = this.state;

        await fetch('/api/invoices', {
            method: (invoice.id) ? 'PATCH' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(invoice),
        }).then((response) => {
            if (response.ok) {
                this.props.history.push(`/invoices/show/${this.props.match.params.customerId}/0`);
            } else {
                if (response.headers.get('reactError') === '') {
                    this.error = 'Error code: ' + response.status;
                    this.forceUpdate();
                } else {
                    this.error = response.headers.get('reactError') + ' (Status: ' + response.status + ')';
                    this.forceUpdate();
                }
            }
        }).catch((error) => {
            this.error = 'Network error';
            this.forceUpdate();
        });
    }

    render() {
        const title = <h2>{this.state.invoice.id ?
            'Edit Invoice #' + this.state.invoice.id + ' of ' + this.state.customer.name + ', IČO ' + this.state.customer.ico + ":"
            :
            'New Invoice for ' + this.state.customer.name + ', IČO ' + this.state.customer.ico + ":"
        }</h2>;
        let errorBox = '';
        if (this.error !== '') {
            errorBox = <div><p class="alert alert-danger"><strong>Error: </strong>{this.error}</p></div>;
        }

        let submitButton = '';
        if (this.props.match.params.command === 'add') {
            submitButton = <Button color="success" type="submit">Submit</Button>
        } else {
            submitButton = <Button color="warning" type="submit">Update</Button>
        }

        let bottomButtonsLink = "";
        if (this.props.match.params.customerId === '0') {
            bottomButtonsLink = "/AllInvoices/show/0/0";
        } else {
            bottomButtonsLink = "/invoices/show/" + this.props.match.params.customerId + "/0";
        }

        if (this.stop) {
            return (
                <div>
                    <Home/>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {errorBox}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
        <div>
            <Home/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {errorBox}
                        {title}
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="due">Total Amount</Label>
                                <Input type="number" name="due" id="due" value={this.state.invoice.due || ''}
                                       onChange={this.handleChange} autoComplete="due"/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="paid">Paid Amount</Label>
                                <Input type="number" name="paid" id="paid" value={this.state.invoice.paid || ''}
                                       onChange={this.handleChange} autoComplete="paid" readOnly="readonly" />
                            </FormGroup>
                            <FormGroup>
                                {submitButton} &nbsp;
                                <Button color="primary" tag={Link} to={bottomButtonsLink}>Cancel</Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        );
    }

}

export default withRouter(InvoiceEdit);